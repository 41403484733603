<template>
    <div class="main">
        <div class="bigImg">
            <img
                :src="
                    navList.find(e => e.id == activeId)
                        ? navList.find(e => e.id == activeId).image
                        : 'http://iv.vu818.com/img/wzyt.jpg'
                "
            />
        </div>
        <div class="content">
            <div class="topNav">
                <div v-for="el in navList" :key="el.id" v-show="el.status" @click="topNavClick(el.id)">
                    <div :class="{ active: activeId == el.id }">
                        {{ el.name }}
                    </div>
                </div>
            </div>
            <list :list="twoList" :callBack="callBack" :itemClick="el => $router.push('/movementDetail/' + el.id)"></list>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import list from "./list.vue";

export default {
    components: { list },
    data() {
        return {
            navList: [],
            activeId: null,
            // twoActiveId: null,
        };
    },
    async created() {
        let { data } = await axios.get("/api/article/onetwo");
        this.navList = data;
        this.activeId = this.$route.query.id ? this.$route.query.id : data[0].id;
    },
    methods: {
        topNavClick(id) {
            this.activeId = id;
            this.$router.replace("/movement?id=" + id);
        },
        // 去详情页
        goDetail(id) {
            // this.twoActiveId = id;
            this.$router.push("/movementDetail/" + id);
        },
        callBack(start, remove) {
            setTimeout(() => {
                remove();
            }, 1000);
        },
    },
    computed: {
        twoList() {
            if (this.navList.length == 0) return [];
            return this.navList[this.navList.findIndex(e => e.id == this.activeId)].children;
        },
    },
    watch: {},
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    .bigImg {
        // height: 400px;
        height: 530px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .content {
        width: 1200px;
        margin: 40px auto;
        .topNav {
            display: flex;
            & > div {
                margin-right: 30px;
                height: 40px;
                line-height: 40px;
                div {
                    width: 150px;
                    margin: 0 auto;
                    border-radius: 20px;
                    text-align: center;
                    box-shadow: 1px 1px 10px 1px #dcdcdc;
                    color: #7781f1;
                    font-weight: 900;
                    cursor: pointer;
                }
            }
            .active {
                background: linear-gradient(90deg, #7781f1, rgba(119, 129, 241, 0.87));
                color: #fff;
            }
        }
        .list {
            text-align: left;
            & > div {
                display: flex;
                margin: 40px 0;
                padding: 10px 30px;
                height: 150px;
                cursor: pointer;
                & > img {
                    width: 200px;
                    margin-right: 10px;
                    transition: 0.5s;
                }
                & > div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    .title {
                        font-size: 20px;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }
                    .intro {
                        flex: 1;
                    }
                    .info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: grey;
                        & > div {
                            display: flex;
                            align-items: center;
                            .yan {
                                overflow: hidden;
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                position: relative;
                                & > img {
                                    width: 1000px;
                                    height: 1500px;
                                    position: absolute;
                                    top: -51px;
                                    right: -309px;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 10px #eee;
                    & > img {
                        transform: scale(1.05);
                    }
                    .title {
                        color: #7781f1;
                    }
                }
            }
            .active {
                border-radius: 20px;
                box-shadow: 1px 1px 10px 10px #eee;
                .title {
                    color: #7781f1;
                }
            }
        }
    }
}
</style>
